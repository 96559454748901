import Layout from "components/layout";
import SEO from "components/seo";
import React, { useEffect } from "react";
import { useRef, useState } from "react";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { fileExtension } from "utils/fileextension";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import { useLocation } from "@reach/router";
import UploadIcon from "assets/uploadIcon.svg";
import CleanButton from "assets/cleanBtn.svg";
import Loader from "components/loader";
import { Tooltip } from "react-tooltip";
import { navigate } from "gatsby";
import CautionIcon from "assets/caution.svg";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import ToolFaq from "components/QualityTools/ToolFaq";
import { StyleGuideToolFaqData } from "components/FAQ/config";

const Index = () => {
  const [sourceText, setSourceText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [error, setError] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [shareId, setShareId] = useState("");
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [file, setFile] = useState(false);
  const [copySuccess, setCopySuccess] = useState("Copy");
  const textareaRef = useRef(null);
  const outputRef = useRef(null);

  const handleTextChange = (e) => {
    const text = e.target.value;

    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);

    // if (words.length > 1000) {
    //   e.target.value = words.slice(0, 1000).join(" ");
    //   return;
    // }

    setWordCount(words.length);
    setSourceText(text);
    setOutputText("");
    adjustHeight();
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, 380);
    textarea.style.height = `${newHeight}px`;
  };

  const getAnonymizeText = async () => {
    try {
      setLoader(true);
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "style_guide_generator",
        user_text: sourceText,
      });
      const newShareId = response?.meta?.share_id;

      setShareId(newShareId);
      window.history.pushState(
        {},
        "",
        `/tools/style-guide-generator?share-id=${newShareId}`
      );
      window.location.reload();
    } catch (error) {
      setError(error);
    } finally {
      setLoader(false);
    }
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = shareId ? shareId : UpdatreshareId;

  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {
      setLoader(true);
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        const { user_text: sourceText } = response.meta;

        setSourceText(sourceText);
        setOutputText(response?.result);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoader(false);
      }
    };
    fatchAnalysis();
  }, [displayShareId]);
  const styleGuideEntries = outputText
    ? Object.entries(outputText).map(([term, definition]) => ({
        Term: term,
        Definition: definition,
      }))
    : [];

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);

      setSourceText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };
  //   function highlightBracketsText(inputText) {
  //     const regex = /\[([^\]]+)\]/g;
  //     const highlightedText = inputText?.replace(
  //       regex,
  //       '<span class="bg-[#FFBDBD]">$&</span>'
  //     );

  //     return highlightedText;
  //   }

  const copyText = () => {
    if (outputRef.current) {
      const textToCopy = outputRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };

  const downloadText = async () => {
    try {
      const textToDownload = document.querySelector("#output_text").innerText;
      if (!textToDownload) {
        console.error("No text found to download.");
        return;
      }
      const blob = new Blob([textToDownload], { type: "text/plain" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "output";
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };

  return (
    <Layout>
      <SEO
        title="Translation Style Guide Generator"
        description="Create detailed style guides for your translation projects with our AI Style Guide Generator. Keep tone, terminology, and formatting consistent."
        slug="/tools/style-guide-generator"
      />
      <div>
        <div className="w-full py-7 ">
          <ToolsBreadcrumb />
        </div>

        <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] lg:pb-[60px] pt-12 pb-8 ">
          <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-2.5">
            Style Guide Generator
          </h1>
          <h2 className="text-[28px]  md:text-3xl leading-[48px]  text-center font-primary  font-bold ">
            Generate an AI Style Guide to Save Time and Reduce Errors
          </h2>
          {/* <p className="hidden md:block mt-6 text-lg text-center font-opensans">
            Enter your text or project details and the tool generates a style
            guide to ensure consistency and accuracy in your translations. This
            helps streamline your workflow and deliver professional results.
          </p> */}
          <div className="mt-12 flex lg:flex-row flex-col gap-4">
            <div className="relative shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans">
              <div className="flex justify-between">
                <span className="font-bold text-lg text-[#6E6E6E]">
                  {" "}
                  Source Text
                </span>
                {sourceText && (
                  <img
                    src={CleanButton}
                    className="cursor-pointer"
                    alt="clean-text-button"
                    onClick={() => {
                      setSourceText("");
                      setOutputText("");
                      setWordCount(0);
                      if (displayShareId) {
                        navigate(`/tools/style-guide-generator/`);
                      }
                    }}
                  />
                )}
              </div>

              <hr className="border border-[#B0B0B0] mt-2 mb-4" />

              {fileUploadLoading ? (
                <FileLoadingInput file={file} />
              ) : sizeLimitError && !fileUploadLoading ? (
                <ReUploadFile
                  file={file}
                  setFile={setFile}
                  setSizeLImitError={setSizeLImitError}
                  uploadFile={uploadFile}
                />
              ) : uploadError && !fileUploadLoading ? (
                <UnsupportedFile
                  file={file}
                  setSizeLImitError={setSizeLImitError}
                  setFile={setFile}
                  uploadFile={uploadFile}
                  setFileUploadError={setUploadError}
                />
              ) : (
                <textarea
                  ref={textareaRef}
                  value={sourceText}
                  onChange={handleTextChange}
                  className={`anonymize_tool outline-none w-full resize-none text-base border-none ${
                    outputRef?.current?.offsetHeight
                      ? `min-h-[${outputRef?.current?.offsetHeight + 54}px]`
                      : outputText
                      ? "lg:h-full lg:pb-12 min-h-[225px]"
                      : "min-h-[225px]"
                  } max-h-[400px]`}
                  placeholder="Enter your text here"
                />
              )}
              <div
                className={`mt-5 w-full bg-white  ${
                  outputText ? "lg:absolute  bottom-0 left-0 p-4 " : ""
                }`}
              >
                <div className="flex md:flex-row items-end flex-col gap-2.5 justify-between">
                  <div className="flex-1">
                    {sourceText ? (
                      <p className="text-base text-[#9C9C9C] ">
                        {wordCount} words
                      </p>
                    ) : (
                      <>
                        <label
                          htmlFor="uploadFile"
                          className="flex gap-2 cursor-pointer"
                        >
                          <img src={UploadIcon} alt="upload_icon" />
                          <span className="text-[#858585] font-sm font-opensans font-semibold">
                            Upload File
                          </span>
                        </label>
                        <input
                          type="file"
                          className="hidden"
                          id="uploadFile"
                          onChange={uploadFile}
                        />
                      </>
                    )}
                  </div>
                  <button
                    disabled={outputText || !sourceText}
                    onClick={getAnonymizeText}
                    className={` outline-none font-opensans font-semibold text-base flex items-center justify-center px-6 py-3 rounded-lg gap-3 border ${
                      !outputText
                        ? "bg-[#5B93FF] border-[#5B93FF]  text-white"
                        : "bg-[#E8E8E8] border-[#E8E8E8] "
                    }`}
                  >
                    {loader ? <Loader /> : "Generate Style Guide"}
                  </button>
                </div>

                {/* <p className="text-[12px] text-[#6E6E6E] bg-[#F0F5FF] p-2 rounded-lg mt-3 font-opensans">
                  This AI tool may not catch all sensitive information. Check
                  the output before sharing.
                </p> */}
              </div>
            </div>
            <div
              className={`relative shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans ${
                outputText && "border border-[#5B93FF]"
              }`}
            >
              <div className="flex justify-between items-center">
                <span
                  className={`font-bold text-lg ${
                    outputText ? "text-[#6E6E6E]" : "text-[#D2D2D2]"
                  }`}
                >
                  Style Guide
                </span>
                <div className="flex gap-3">
                  <button
                    data-tooltip-id="copy-tooltip"
                    data-tooltip-content={copySuccess}
                    onClick={copyText}
                    className=""
                  >
                    <img src={CopyIcon} alt="/copyicons" />
                  </button>
                  <button
                    data-tooltip-id="download-tooltip"
                    data-tooltip-content="Download"
                    onClick={downloadText}
                    className=""
                  >
                    <img src={DownloadIcon} alt="/downloadicon" />
                  </button>

                  <Tooltip
                    id="copy-tooltip"
                    place="top"
                    multiline={true}
                    className="z-50"
                  />
                  <Tooltip
                    id="download-tooltip"
                    place="top"
                    multiline={true}
                    className="z-50"
                  />
                </div>
              </div>
              <hr
                className={`border mt-2 mb-4 ${
                  outputText ? "border-[#B0B0B0]" : "border-[#D2D2D2]"
                }`}
              />
              {outputText ? (
                <div
                  ref={outputRef}
                  className={`flex items-center justify-center flex-col w-full ${
                    !outputText ? "-mt-5" : "pt-16"
                  } overflow-x-hidden anonymize_tool  border-none pr-2 ${
                    outputRef?.current?.offsetHeight
                      ? `min-h-[${outputRef?.current?.offsetHeight + 54}px]`
                      : outputText
                      ? "h-full "
                      : "min-h-[225px]"
                  }  ${outputRef ? "max-h-[500px]" : "max-h-[400px]"} `}
                  //   className={` flex items-center justify-center flex-col  ${
                  //     !outputText ? "-mt-5" : ""
                  //   }`}
                >
                  {!outputText ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="106"
                        height="97"
                        viewBox="0 0 106 97"
                        fill="none"
                      >
                        <path
                          d="M106 11.7065V65.2253C106 71.6884 100.811 76.9318 94.4056 76.9318H42.1714L23.816 97V76.93H11.5944C5.19215 76.93 0 71.6866 0 65.2235V11.7065C0.00177002 5.24159 5.19215 0 11.5944 0H94.4056C100.811 0 106 5.24159 106 11.7065ZM32.1652 38.4641C32.1652 34.3093 28.8298 30.9394 24.7117 30.9394C20.5937 30.9394 17.2583 34.3093 17.2583 38.4641C17.2583 42.6225 20.5937 45.9906 24.7117 45.9906C28.8298 45.9906 32.1652 42.6243 32.1652 38.4641ZM60.4543 38.4641C60.4543 34.3093 57.1154 30.9394 53.0009 30.9394C48.8864 30.9394 45.5474 34.3093 45.5474 38.4641C45.5474 42.6225 48.8864 45.9906 53.0009 45.9906C57.1154 45.9906 60.4543 42.6243 60.4543 38.4641ZM88.7417 38.4641C88.7417 34.3093 85.4028 30.9394 81.2883 30.9394C77.1702 30.9394 73.8348 34.3093 73.8348 38.4641C73.8348 42.6225 77.1702 45.9906 81.2883 45.9906C85.4045 45.9924 88.7417 42.6243 88.7417 38.4641Z"
                          fill="#E8E8E8"
                        />
                      </svg>
                      <p className="text-center mt-8 text-[#858585]">
                        Your results will appear here.
                        <br /> Please enter your texts and click "Redact Data"
                        to begin.
                      </p>
                    </>
                  ) : (
                    <div className="flex flex-col gap-2.5 w-full py-20" id="">
                      {styleGuideEntries &&
                        styleGuideEntries.map((item, index) => (
                          <div
                            key={index}
                            className={`font-opensans rounded-lg bg-[#F0F5FF] p-4 w-full`}
                          >
                            <span
                              className={`text-base  text-[#858585] font-opensans font-bold`}
                            >
                              {item.Term}
                            </span>
                            <p className="text-[#424242] text-sm mt-2 font-opensans font-normal">
                              {item.Definition}
                            </p>
                          </div>
                        ))}

                      {/* <hr className="border border-[#B0B0B0]" /> */}

                      {/* <div className="bg-[#F0F5FF] rounded-md p-3 flex items-center gap-5 justify-between mt-3 absolute bottom-[14px] left-0 mx-3 ">
                        <img src={CautionIcon} alt="caution-icon" />
                        <p className="text-[12px] text-[#6E6E6E] font-opensans">
                          Check the redacted result to ensure all private
                          information is removed and avoid accidental disclosure
                        </p>
                      </div> */}
                    </div>
                  )}
                </div>
              ) : (
                <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
                  {error?.message}
                </span>
              )}
            </div>
          </div>
          <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <p className="mt-8 md:mt-12 text-lg text-center font-opensans">
            Enter your text or project details and the tool generates a style
            guide to ensure consistency and accuracy in your translations. This
            helps streamline your workflow and deliver professional results.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={StyleGuideToolFaqData} />
    </Layout>
  );
};
export default Index;
